import { Component, OnInit } from '@angular/core';
import { WeatherService } from '../../../services/weather.service';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-weather',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './weather.component.html',
  styleUrl: './weather.component.scss',
})
export class WeatherComponent implements OnInit {
  temperatureKelvin!: number;
  temperatureCelsius!: number;
  city!: string;
  dicription: any;
  country: any;
  icponUrl: any;
  constructor(private weatherService: WeatherService, public route: Router) {}
  ngOnInit(): void {
    this.getLocation();
  }
  //get user location
  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        if (latitude & longitude) {
          this.getCity(latitude, longitude);
          this.getWeather(latitude, longitude);
        }
      });
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  }

  //get the city by lat & long
  getCity(lat: number, long: number) {
    this.weatherService.getCity(lat, long).subscribe((data: any) => {
      this.city = data?.city;
      this.country = data?.countryName;
    });
  }

  //get the weather by lat & long
  getWeather(latitude:number, longitude:number) {
    this.weatherService
      .getWeather(latitude, longitude)
      .subscribe((data: any) => {

        // this.temperatureKelvin = data.main.temp;
        this.temperatureCelsius = data?.current?.temp_c;
        this.icponUrl = data?.current?.condition?.icon;
        // this.dicription = data.weather.description;
      });
  }

  //temp from weather service is getting by Kelvin then will convert it to Celsius
  convertKelvinToCelsius(kelvin: number): number {
    return kelvin - 273.15;
  }
}
