        <div class="head-title mt-2 row">
            <div class="col-12">
              <h1 class="fw-light radius-05">Media Gallery</h1>
            </div>
          </div>

          <div class="cardfourth row row-cols-2 row-cols-sm-2 row-cols-md-2 g-2">
            <div class="col" *ngFor="let item of media.slice(0, 2)">
              <div class="card no-border radius-05">
                <img src="assets/img/s3.jpg" class="card-img-top" alt="Meine">
                <div class="card-body">
                  <h5 class="card-title">Special title treatment</h5>
                  <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
                </div>
              </div>
            </div>

          </div>