<div class="apps-sections h-100">
  <!-- Title -->
  <h3 *ngIf="this.byEmeis == true">{{"applications"|translate}}</h3>
  <h3 *ngIf="this.byEmeis == false">USER MANAGED APPS/LINKS</h3>
  <hr />

  <div class="cards-container px-2" *ngIf="projects?.length">
    <div
      class="app-card bg-white h-100"
      *ngFor="
        let project of projects
          | paginate
            : {
                itemsPerPage: pageSize,
                currentPage: pageNumber,
                totalItems: totalCount,
                id:'apps'
              };
        let i = index
      "
      [class.d-none]="!project.isActive"
    >
      <a [href]="project?.link" target="_blank">
        <app-project-card
          [data]="project"
          [lettersNumber]="17"
          class="h-100"
        ></app-project-card>
      </a>
      <!-- <div style="cursor: pointer;" [routerLink]="project.link" *ngIf="this.byEmeis == false">
        <app-project-card
          [data]="project"
          [lettersNumber]="17"
          class="h-100"
        ></app-project-card>
      </div> -->
    </div>
  </div>
  <div class="d-flex justify-content-center mt-4" *ngIf="this.byEmeis == true">
    <pagination-controls
      class="align-items-center pagination justify-content-center font-secondary my-pagination align-self-md-end"
      class="pagination justify-content-center font-secondary my-pagination"
      [autoHide]="true"
      (pageChange)="paginate($event)"
      nextLabel=""
      previousLabel=""
      id="apps"
    >
    </pagination-controls>
  </div>
</div>
