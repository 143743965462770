import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-news',
  standalone: true,
  imports: [CommonModule, CarouselModule],
  templateUrl: './news.component.html',
  styleUrl: './news.component.scss',
})
export class NewsComponent {
  image!: string;
  @ViewChild('fullWidthButton') fullWidthButton!: ElementRef;
  @ViewChild('fulltextButton') fulltextButton!: ElementRef;
  slid!: any;
  constructor(public route: Router, public sanitizer: DomSanitizer) {}
  data: any[] = [
    {
      img: '../../../assets/news/Folie2.jpeg',
      title:
        'Das <span class="title fs-32"> Komm.UNITY Framework </span> etabliert die Regeln und Standards für die Anbindung neuer Applikationen an den Komm.UNITY Core Durch die Definition klarer Schnittstellen und Protokolle wird die Integration neuer Services vereinfacht und standardisiert. Dies fördert die Kompatibilität und Austauschbarkeit der verschiedenen Applikationen und Dienste. Das Framework sichert somit eine homogene IT- Landschaft und erleichtert die Erweiterung von Komm.UNITY um neue Funktionen und Services.',
      details: '',
    },
    {
      img: '../../../assets/news/Folie4.jpeg',
      title:
        'Der <span class="title fs-32"> Komm.UNITY Core </span> dient als das Herzstück des Systems und bietet eine zentrale Plattform für das Usermanagement aus der Cloud Diese Basis ermöglicht es, unterschiedliche Zielgruppen – Bürger, Sachbearbeiter und Themenportale – effektiv zu bedienen. Ein wesentliches Feature ist das Launchpad, über das Applikationen dynamisch in die jeweiligen Portale integriert werden können. Dies erlaubt eine flexible Anpassung und Darstellung userspezifischer Applikationen und Datenquellen, unabhängig von deren Ursprung. Diese Applikationen werden dynamisch und automatisiert in die Portale ausgespielt. Die zentrale Verwaltung und flexible Einbindung machen den Core zu einem leistungsfähigen Werkzeug innerhalb von Komm.UNITY',
      details: '',
    },
    {
      img: '../../../assets/news/Folie6.jpeg',
      title:
        'In Anbetracht der Herausforderungen und der politischen Rahmenbedingungen empfiehlt sich die Implementierung eines Kommunity- fokussierten Launch-Ansatzes für das neue Portal. Diese Strategie konzentriert sich auf den Aufbau und die aktive Einbindung einer Kommunity im Vorfeld und während des offiziellen Starts des Portals.',
    },
  ];

  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: true,
    navSpeed: 900,
    navText: ['', ''],
    margin: 10,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      740: {
        items: 1,
      },
      940: {
        items: 1,
      },
      1024: {
        items: 1,
      },
    },
    nav: false,
  };

  openFullWidthImage(item: any) {
    console.log(item);

    this.image = item?.img;
    this.fullWidthButton.nativeElement.click();
    const elem = document.getElementById('bd-example-modal-xl');
    if (elem?.requestFullscreen) {
      elem.requestFullscreen();
    } else if ((<any>elem).mozRequestFullScreen) {
      // Firefox
      (<any>elem).mozRequestFullScreen();
    } else if ((<any>elem).webkitRequestFullscreen) {
      // Chrome, Safari and Opera
      (<any>elem).webkitRequestFullscreen();
    } else if ((<any>elem).msRequestFullscreen) {
      // IE/Edge
      (<any>elem).msRequestFullscreen();
    }
  }
  openFullWidthText(item: any) {
    console.log(item);

    this.slid = item;
    this.fulltextButton.nativeElement.click();
      const elem = document.getElementById('text-example-modal-xl');
      if (elem?.requestFullscreen) {
        elem.requestFullscreen();
      } else if ((<any>elem).mozRequestFullScreen) {
        // Firefox
        (<any>elem).mozRequestFullScreen();
      } else if ((<any>elem).webkitRequestFullscreen) {
        // Chrome, Safari and Opera
        (<any>elem).webkitRequestFullscreen();
      } else if ((<any>elem).msRequestFullscreen) {
        // IE/Edge
        (<any>elem).msRequestFullscreen();
      }
  }
  getSanitizedTitle(title: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(title);
  }

  closeSlide(): void {
    this.slid = null;
  }
}
