<div class="cardspecial mt-1 row row-cols-1 row-cols-sm-1 row-cols-md-1 g-1">
  <div class="col">
    <div class="card no-border radius-05">
      <div class="card-body">
        <h5 class="card-title">Special 2</h5>
        <p class="card-text">With supporting2</p>
      </div>
    </div>
  </div>
</div>
