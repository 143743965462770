import { Component } from '@angular/core';

@Component({
  selector: 'app-memories',
  standalone: true,
  imports: [],
  templateUrl: './memories.component.html',
  styleUrl: './memories.component.scss'
})
export class MemoriesComponent {

}
