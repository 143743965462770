<div
  *ngIf="!route?.url?.includes('/portal')"
  class="cardsix row row-cols-2 row-cols-lg-4 row-cols-md-2 g-2"
>
  <div class="col">
    <div class="card no-border h-100 radius-05">
      <div class="card-body px-1">
        <div class="icon">
          <img src="assets/img/experiment.png" />
          <h5 class="card-title">Onboarding</h5>
        </div>
      </div>
    </div>
  </div>
  <div class="col">
    <div class="card no-border h-100 radius-05">
      <div class="card-body px-1">
        <div class="icon">
          <img src="assets/img/experiment.png" />
          <h5 class="card-title">IT-service</h5>
        </div>
      </div>
    </div>
  </div>
  <div class="col">
    <div class="card no-border h-100 radius-05" [routerLink]="['/auth/newPassword']">
      <div class="card-body px-1">
        <div class="icon">
          <img src="assets/img/experiment.png" />
          <h5 class="card-title">Password</h5>
        </div>
      </div>
    </div>
  </div>
  <div class="col">
    <div class="card h-100 no-border radius-05">
      <div class="card-body px-1">
        <div
          class="icon"
          style="text-align: center; padding: 20px 0px; color: #ffffff"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="45"
            height="45"
            fill="currentColor"
            class="bi bi-plus"
            viewBox="0 0 16 16"
          >
            <path
              d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="route?.url?.includes('/portal')" class="cardsix row row-cols-2 row-cols-lg-4 row-cols-md-2 g-2">
  <div class="col">
    <div class="card no-border h-100 card-landing radius-05">
      <div class="card-body px-1">
        <div class="icon">
          <img src="../../../../assets/Svgs/onBoarding.svg" />
          <h5 class="card-title" i18n="@@onboardingTitle">{{ 'setting.onboardingTitle' | translate }}</h5>
        </div>
      </div>
    </div>
  </div>
  <div class="col">
    <div class="card no-border h-100 card-landing radius-05">
      <div class="card-body px-1">
        <div class="icon">
          <img src="../../../../assets/Svgs/itService.svg" />
          <h5 class="card-title" i18n="@@itServiceTitle">{{ 'setting.itServiceTitle' | translate }}</h5>
        </div>
      </div>
    </div>
  </div>
  <div class="col">
    <div class="card no-border h-100 card-landing radius-05" [routerLink]="['/auth/newPassword']">
      <div class="card-body px-1">
        <div class="icon">
          <img src="../../../../assets/Svgs/passwords.svg" />
          <h5 class="card-title" i18n="@@passwordTitle">{{ 'setting.passwordTitle' | translate }}</h5>
        </div>
      </div>
    </div>
  </div>
  <div class="col">
    <a target="_blank" class="card no-border h-100 card-landing radius-05" href="https://wtz-tagungszentrum.de/wp-content/uploads/2024/07/KW-28-08.-12.07.2024.pdf">
      <div class="card-body px-1">
        <div class="icon">
          <img src="../../../../assets/Svgs/menu.svg" />
          <h5 class="card-title" i18n="@@menuTitle">{{ 'setting.menuTitle' | translate }}</h5>
        </div>
      </div>
    </a>
  </div>
</div>

