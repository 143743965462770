       <div class="cardslist mt-1 row row-cols-1 row-cols-sm-1 row-cols-md-1 g-1">
            <div class="col">
              <ul class="list-group">
                <li class="list-group-item align-items-center">
                  <h2 style="text-align: center; margin: 10px 0px 22px;">Trainings</h2>
                    
                </li>
                  <li class="list-group-item align-items-center">
                    <a class="btn btn-floating m-1" style="float: right;" href="#!" role="button">
                        <i class="fa fa-chevron-right"></i>
                    </a>
                      <h6>Dapibus ac facilisis</h6>
                      <p>Dapibus ac facilisis</p>
                      
                  </li>
                  <li class="list-group-item align-items-center">
                    <a class="btn btn-floating m-1" style="float: right;" href="#!" role="button">
                        <i class="fa fa-chevron-right"></i>
                    </a>
                      <h6>Dapibus ac facilisis</h6>
                      <p>Dapibus ac facilisis</p>
                      
                  </li>
                  <li class="list-group-item align-items-center">
                    <a class="btn btn-floating m-1" style="float: right;" href="#!" role="button">
                        <i class="fa fa-chevron-right"></i>
                    </a>
                      <h6>Dapibus ac facilisis</h6>
                      <p>Dapibus ac facilisis</p>
                      
                  </li>
                  <!-- <li class="list-group-item align-items-center">
                    <a class="btn btn-floating m-1" style="float: right;" href="#!" role="button">
                        <i class="fa fa-chevron-right"></i>
                    </a>
                      <h6>Dapibus ac facilisis</h6>
                      <p>Dapibus ac facilisis</p>
                      
                  </li> -->
                  <li class="list-group-item align-items-center">
                    <a class="btn btn-floating m-1" style="float: right;" href="#!" role="button">
                        <i class="fa fa-chevron-right"></i>
                    </a>
                      <h6>Dapibus ac facilisis</h6>
                      <p>Dapibus ac facilisis</p>
                      
                  </li>

              </ul>
              
            </div>
          </div>