<div class="app-tile__top" *ngIf="data">
  <div class="app-tile__logo">
    <img
      [src]="data.imageBase64 | transform"
      [alt]="data.imageBase64.name"
      class="app-img"
      [title]="data.imageBase64.name"
    />
  </div>
  <div class="app-tile__title">
    <div>{{ data.name }}</div>
    <b>{{ data.description | truncate : lettersNumber }}</b>
  </div>
</div>
<div class="app-tile__line"></div>
<div class="app-tile__bottom">
  <a [href]="data.link">
    <button class="button-icon">
      <i class="fa-regular fa-circle-right"></i>
      <span> {{ "moreInfo" | translate }} </span>
    </button>
  </a>
  <button *ngIf="!data?.isFavourite" class="bg-transparent border-0">
    <img src="../../../../../assets/Svgs/bookmark.svg" alt="" />
  </button>
  <button *ngIf="data?.isFavourite" class="button-icon">
    <i class="fa-solid fa-bookmark"></i>
  </button>
</div>
