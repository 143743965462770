import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { App } from '../_models/app.model';

@Injectable({
  providedIn: 'root',
})
export class ProjectsService {
  auth!: Observable<Boolean>;
  constructor(private http: HttpClient) {}

  getStaticApps() {
    return this.http.get<App[]>(environment.apiUrl + '/applications');
  }
  getStaticAppsForUser() {
    return this.http.get<any>('/assets/files/applications.json');
  }
  // Fetch applications with admin scope
  getApplicationsForAdmin() {
    return this.http.get<any>(
      environment.apiUrl + '/applications/filter?assignedRole=admin'
    );
  }

  getApplicationsByUserId(userId: string) {
    return this.http.get<App[]>(
      environment.apiUrl + `/applications/filter?assignedUser=${userId}`
    );
  }
  // Fetch applications with user scope
  getApplicationsForUser() {
    return this.http.get<any>(
      environment.apiUrl + '/applications/filter?assignedRole=user'
    );
  }

  //fetch projects for user
  getProjectsByUserName(userName: string) {
    return this.http.get<any>(
      environment.apiUrl + `/projects/filter?assignedUser=${userName}`
    );
  }
}
