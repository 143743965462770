<div
  *ngIf="!route?.url?.includes('/portal')"
  class="cardseven row row-cols-1 row-cols-sm-1 row-cols-md-1 h-100 g-2"
>
  <div class="col">
    <div class="card h-100 no-border radius-05">
      <div class="card-body">
        <div class="icon">{{ temperatureCelsius }} °C</div>
        <h5 class="card-title">{{ city }}</h5>
        <p class="card-text">{{ dicription }}</p>
      </div>
    </div>
  </div>
</div>

<div
  *ngIf="route?.url?.includes('/portal')"
  class="layout-item align-items-center d-flex gap-4 p-3 m-0"
>
  <h2 class="temp gap-2 d-flex">
    <img [src]="icponUrl"/>
    <!-- <img
      *ngIf="temperatureCelsius < 25"
      width="50px"
      src="../../../../assets/Svgs/cloudy.svg"
    />
    <img
      *ngIf="temperatureCelsius > 35"
      width="50px"
      src="../../../../assets/Svgs/Sunnyday.svg"
    />
    <img
      *ngIf="temperatureCelsius >= 25 && temperatureCelsius <= 35"
      width="50px"
      src="../../../../assets/Svgs/Storm-amico.svg"
    /> -->
    <span class="d-flex gap-1 align-items-center">
      {{ temperatureCelsius }}
      <div class="d-flex flex-column pt-2">
        <span  class="celc">°C</span>
        <!-- <p class="m-0" style="font-size: 14px">{{ dicription }}</p> -->
      </div>
    </span>
  </h2>
  <div>
    <h3>{{ city }} , {{ country }}</h3>
  </div>
</div>
