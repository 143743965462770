import { Component, importProvidersFrom, inject } from '@angular/core';
import { ProjectsService } from '../../../services/projects.service';
import { CommonModule } from '@angular/common';
import { AuthService } from '../../../auth/services/auth.service';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { IUser } from '../../../_models/login.model';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { PrimeNGConfig } from 'primeng/api';
import { TranslationService } from '../../../services/translation.service';

@Component({
  selector: 'app-nav-bar',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    RouterLinkActive,
    FormsModule,
    ReactiveFormsModule,
    NgbDropdownModule,
    DropdownModule,
    TranslateModule,
  ],
  templateUrl: './nav-bar.component.html',
  styleUrl: './nav-bar.component.scss',
})
export class NavBarComponent {
  isAuth: Boolean = false;
  translate = inject(TranslateService);
  translation = inject(TranslationService);
  userData!: IUser | null;
  lang: any = { name: '', code: '' };
  languages = [
    { name: 'EN', code: 'en' },
    { name: 'DE', code: 'de' },
  ];
  constructor(
    public authService: AuthService,
    private route: Router,
    public oidcSecurityService: OidcSecurityService,
    private config: PrimeNGConfig
  ) {
    this.lang.code = localStorage.getItem('lang')
      ? localStorage.getItem('lang')
      : this.languages[1].code;
    this.lang.name = this.lang.code.toUpperCase();
    this.translate.setDefaultLang(this.lang.code);
    this.translation.changLanguage(this.lang.code);
    this.authService.userSb.subscribe((data) => {
      this.isAuth = data;
    });

    this.authService.userData.subscribe((data) => {
      this.userData = data;
    });
  }

  logOut() {
    this.oidcSecurityService.logoff().subscribe((result) => {});
  }

  switchLanguage(lang: any) {
    localStorage.setItem('lang', lang.code);
    this.translate.use(lang.code);
    this.translation.changLanguage(lang.code)
  }
}
