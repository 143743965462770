<header class="header">
  <div class="header__wrap">
    <a class="header__logo-box d-flex gap-1" [routerLink]="['/portal']">
      <img src="../../../../assets/img/Komm.Unity.png" alt="" />
    </a>
    <!-- <nav class="navigation" *ngIf="isAuth">
      <ul class="navigation__list">
        <li class="navigation__item">
          <a
            class="navigation__link"
            [routerLinkActive]="['navigation__link--active']"
            [routerLink]="['/portal/favourite']"
            ><i class="fa fa-light fa-bookmark"></i> <span>Favoriten</span></a
          >
        </li>
        <li class="navigation__item">
          <a
            class="navigation__link "
            [routerLinkActive]="['navigation__link--active']"
            [routerLink]="['/portal/apps']"
            ><img src="../../../../assets/img/9041403_grid_fill_icon.svg"> <span>Apps</span></a
          >
        </li>
      </ul>
    </nav> -->
    <p-dropdown  [options]="languages" optionLabel="name" [(ngModel)]="lang" (onChange)="switchLanguage(lang)">
      <ng-template pTemplate="selectedItem">
        <div class="d-flex align-items-center gap-2" *ngIf="lang">
          <img
            src="../../../../assets/{{ lang.code }}.svg"
            [class]="'flag flag-' + lang.code"
            style="width: 18px"
          />
          <!-- <div>{{ lang.name }}</div> -->
        </div>
      </ng-template>
      <ng-template pTemplate="item" let-language>
        <div class="d-flex align-items-center gap-2">
          <img
            src="../../../../assets/{{ language.code }}.svg"
            [class]="'flag flag-' + language.code"
            style="width: 18px"
          />
          <div>{{ language.name }}</div>
        </div>
      </ng-template>
    </p-dropdown>

 <div class="header__profile" *ngIf="isAuth">
  <div class="dropdown custom-dropdown">
    <button
      class="btn btn-secondary dropdown-toggle custom-dropdown__button"
      type="button"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      <span class="custom-dropdown__picture"
        ><i class="fa fa-regular fa-user"></i
      ></span>
      <span class="custom-dropdown__text">{{ userData?.name }}</span>
    </button>
    <ul class="dropdown-menu custom-dropdown__menu">
      <li>
        <a class="dropdown-item custom-dropdown__item" [routerLink]="['/portal']">{{ 'navBar.aboutTitle' | translate }}</a>
      </li>
      <li>
        <a class="dropdown-item custom-dropdown__item" [routerLink]="['/auth/profile']">{{ 'navBar.profileTitle' | translate }}</a>
      </li>
      <li>
        <a class="dropdown-item custom-dropdown__item" [routerLink]="['/auth/newPassword']">{{ 'navBar.newPasswordTitle' | translate }}</a>
      </li>
      <li>
        <a class="dropdown-item custom-dropdown__item" (click)="logOut()">{{ 'navBar.logoutTitle' | translate }}</a>
      </li>
    </ul>
  </div>
</div>

  </div>
</header>
