import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class WeatherService {
  constructor(private http: HttpClient) {}
  private apiUrl = 'https://api.bigdatacloud.net/data/reverse-geocode-client';

  getWeather(latitude:number , longitude:number) {
    const apiUrl = `https://api.weatherapi.com/v1/current.json?key=${environment.weatherKey}&q=${latitude},${longitude}`;
    return this.http.get(apiUrl);
  }

  getCity(latitude: number, longitude: number) {
    const url = `${this.apiUrl}?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`;
    return this.http.get(url);
  }
}
