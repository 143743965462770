import { Component, Input, OnInit } from '@angular/core';
import { App } from '../../../../_models/app.model';
import { CommonModule } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { TransformPipe } from '../../../../pipes/transform.pipe';
import { TruncatePipe } from '../../../../pipes/truncate.pipe';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-project-card',
  standalone: true,
  imports: [CommonModule, TransformPipe, TruncatePipe,RouterLink,TranslateModule],
  templateUrl: './project-card.component.html',
  styleUrl: './project-card.component.scss',
})
export class ProjectCardComponent {
  @Input({ required: true }) data!: App;
  @Input({ required: true }) lettersNumber!: number;
  constructor(private _sanitizer: DomSanitizer) {}
}
