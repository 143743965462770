
          <div class="head-title mt-2 row">
            <div class="col-12">
              <h1 class="fw-light radius-05">Meine Tools</h1>
            </div>
          </div>

          <div class="cardfive row row-cols-3 row-cols-sm-3 row-cols-md-3 g-2">
            <div class="col" *ngFor="let tool of tools.slice(0, 3)">
              <div class="card no-border radius-05">
                <div class="card-body">
                  <div class="icon">
                    <img src="assets/img/experiment.png" />
                  </div>
                </div>
              </div>
            </div>
          </div>