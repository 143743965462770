      <div class="head-title row">
            <div class="col-12">
              <h1 class="fw-light mt-2 radius-05">Social Corner</h1>
            </div>
          </div>

          <div class="cardfourth row row-cols-2 row-cols-sm-2 row-cols-md-2 g-2" >
            <div class="col">
              <div class="card no-border radius-05" style="background: #01b2aa;">
                <img src="assets/img/s3.jpg" class="card-img-top mid" alt="Meine">
                <div class="card-body">
                  <h5 class="card-title">Special title treatment</h5>
                  <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card no-border radius-05" style="background: #01b2aa;">
                <img src="assets/img/s3.jpg" class="card-img-top mid" alt="Meine">
                <div class="card-body">
                  <h5 class="card-title">Special title treatment</h5>
                  <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card no-border radius-05" style="background: #01b2aa;">
                <img src="assets/img/s3.jpg" class="card-img-top mid" alt="Meine">
                <div class="card-body">
                  <h5 class="card-title">Special title treatment</h5>
                  <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card no-border radius-05" style="background: #01b2aa;">
                <img src="assets/img/s3.jpg" class="card-img-top mid" alt="Meine">
                <div class="card-body">
                  <h5 class="card-title">Special title treatment</h5>
                  <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
                </div>
              </div>
            </div>
          </div>